import React from "react"
import PropTypes from "prop-types"
import { ShieldCheckIcon } from "@heroicons/react/outline"

function Features({ data, landingPage }) {

  let items = [
    {title: "Learn to trade", text: "The Forex Analytix Trader Funding Program can help you improve your trading skills, no matter what your level of experience or previous trading results."},
    {title: "Get trade ideas", text: "Use the Forex Analytix service and interact with other members of our community to generate trade ideas, and potentially turn them into gains."},
    { title: "Get Funded", text: "<b>1-Step Assessment Plans</b>:  Pass our assessment on a DEMO account and Access Your Live Account!<br/><br/><b>Immediate Live Trading Accounts:</b> Trade a funded live account immediately!"},
    {title: "Be supported by a trading community", text: "Everywhere else, once you Get Funded you are on your own – with Forex Analytix, that’s just the beginning of your trading journey."},
    {title: "Only at Forex Analytix", text: "Take full advantage of the Forex Analytix service and community to support you in your exciting journey. Analysis, Charts, Ideas, Chatrooms, Webinars and more!"},
    {title: "Trader Funding Program by Forex Analytix", text: "Register now and take the first exciting step forward in your trading journey!"},
  ];
  if (landingPage == 'usa-new-york') (
    items = [
    {title: "Learn to trade", text: "The Forex Analytix Trader Funding Program can help you improve your trading skills and develop the confidence, discipline, and knowledge base that is necessary to succeed in the Forex market"},
    {title: "Get trade ideas", text: "The Forex Analytix service is a great way to interact with other members of our community and generate fresh ideas, that may potentially lead into more gains."},
    { title: "Get Funded", text: "We’re seeking skilled traders! Prove yourself on a DEMO account to qualify for a funded live account. Or, start trading instantly with an Immediate Live Trading Account."},
    {title: "Be supported by a trading community", text: "Everywhere else, as soon as you become funded trader you are by yourself. With Forex Analytix, funding signifies only the beginning of your journey."},
    {title: "Only at Forex Analytix", text: "Utilize the Forex Analytix service in the best way possible. Our community can help you out along your journey at a funded trading program. We've got analysis, charts, ideas, chatrooms, webinars—all sorts of stuff."},
    {title: "Trader Funding Program by Forex Analytix", text: "Register now and take your first exciting step towards mastering the markets!"},
  ])
  else if (landingPage == 'uk-london') (
    items = [
    {title: "Learn to trade", text: "Develop your trading skills and succeed in the Forex Market, with the Forex Analytix Trader Funding Program."},
    {title: "Get trade ideas", text: "Interact with other enthusiastic members of our community and share trading ideas and inspiration to kickstart your gains."},
    { title: "Get Funded", text: "Think you’ve got what it takes? Pass our DEMO account assessment to qualify for a funded live account, or start trading right away with our Immediate Live Trading Accounts!"},
    {title: "Be supported by a trading community", text: "With Forex Analytix, you get ongoing support from our vast community. Becoming a funded trader is just the beginning of your path to success."},
    {title: "Only at Forex Analytix", text: "Begin your journey as a funded trader with all the necessary tools at hand. We’ve got Analysis, Charts, Ideas, Chatrooms, Webinars, and all you could possibly need!"},
    {title: "Trader Funding Program by Forex Analytix", text: "Join us today and take your big step into the world of trading!"},
  ])
  else if (landingPage == 'canada-toronto') (
    items = [
    {title: "Learn to trade", text: "The Forex Analytix Trader Funding Program can help you acquire the skills and knowledge you need, to succeed in the Forex market."},
    {title: "Get trade ideas", text: "The Forex Analytix group is a great place to connect with other members of our community, who are passionate about sharing trading ideas."},
    { title: "Get Funded", text: "Ready to take on the market? Prove your skills on a DEMO account to qualify for a live account with real funds, or start trading instantly with our Immediate Live Trading Accounts!"},
    {title: "Be supported by a trading community", text: "When you become a funded trader at Forex Analytix, you’re not on your own. Our community is always by your side to guide you through your journey."},
    {title: "Only at Forex Analytix", text: "Forex Analytix has the specialized toolkit that is essential to your success at a funded trading program. Charts, ideas, analysis, and webinars, all easily accessible any time you need!"},
    {title: "Trader Funding Program by Forex Analytix", text: "Become a member now and start your trading the right way!"},
  ])
  else if (landingPage == 'australia-sydney') (
    items = [
    {title: "Learn to trade", text: "The Forex Analytix Trader Funding Program can help you improve your trading skills, no matter how much—or how little—experience you have."},
    {title: "Get trade ideas", text: "The Forex Analytix Service is the best place to interact with other members of our community who are enthusiastic about sharing their trading ideas."},
    { title: "Get Funded", text: "We’re seeking traders who can beat the market. Pass our DEMO assessment, show your skills, and if you qualify, get access to a live account with real funds. Or, start trading instantly with our Immediate Live Trading Accounts!"},
    {title: "Be supported by a trading community", text: "With Forex Analytics you’re never alone! Become a funded trader and get the support of an expansive trading community."},
    {title: "Only at Forex Analytix", text: "Forex Analytix has the tools and resources essential to your success at a funded trading program. Charts, ideas, analysis, and webinars all at your fingertips!"},
    {title: "Trader Funding Program by Forex Analytix", text: "Let's get started on your exciting trading journey! "},
  ])
  else if (landingPage == 'india-mumbai') (
    items = [
    {title: "Learn to trade", text: "The Forex Analytix Trader Funding Program offers you a step-by-step course to help you build the skills and knowledge you need to succeed in the Forex market."},
    {title: "Get trade ideas", text: "Connect with other members of our community, share your ideas, and turn them into account gains. "},
    { title: "Get Funded", text: "Ready to beat the market? <br/>Pass our DEMO assessment, meet the requirements, and get access to a live account with real funds.<br/>Or, start trading right away with our Immediate Live Trading Accounts!"},
    {title: "Be supported by a trading community", text: "In any other instance, you get funded and you’re by yourself. When you become a funded trader at Forex Analytix, we’re right there with you."},
    {title: "Only at Forex Analytix", text: "Get access to charts, ideas, analysis, webinars, and so much more tools the Forex Analytix service has to offer!"},
    {title: "Trader Funding Program by Forex Analytix", text: "Sign up today and start building a strong trading portfolio!"},
  ])

  return (
    <div className="relative py-16 bg-white sm:py-24 lg:py-16" id="how-it-works">
      <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <>
          <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
            HOW IT WORKS
          </h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-black sm:text-4xl">
            The <span className="">Trader Funding Program</span> gives you <br/>
            the chance to trade your own live funded account.
          </p>
          <p className="mx-auto mt-5 text-xl text-black max-w-prose">
            There are other places you could get funded to trade. There is no other place that offers you the ongoing support that Forex Analytix can give you
          </p>
          <p className="mx-auto mt-5 text-black max-w-prose font-bold text-3xl">
            Choose between an <span className="text-indigo-600">Immediate Live Trading Account</span> and a <span className="text-indigo-600">1-Step Assessment Plan</span>, depending on your own requirements
          </p>
          <br/>
        </>

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {items.map((item, index) => (
              <div key={index} className="pt-6">
                <div className="flow-root px-6 pb-8 rounded-lg bg-gray-50 h-64">
                  <div className="-mt-6">
                    <div>
                      <span className="rounded-md inline-flex items-center justify-center p-3 bg-green shadow-lg">
                        <ShieldCheckIcon
                          className="w-6 h-6 text-gray-600"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-black">
                      {item?.title}
                    </h3>
                    <p className="mt-5 text-base text-black text-left" dangerouslySetInnerHTML={{ __html: item?.text  }}>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Features.propTypes = {
  sectionHeading: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  items: PropTypes.array,
}

export default Features
